import { Component, effect, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { SplashScreenComponent } from './layout/splash-screen/splash-screen.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ThemeService } from './shared/theme.service';
import { TranslationService } from './shared/translation.service';
import { AuthService } from './auth/auth.service';
import { UserSettingService } from './services/user-setting.service';
import { MediaService } from './services/media.service';
import { SharedService } from './shared/services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatService } from './services/chat/chat.service';
import { filter, map, of, pairwise, switchMap } from 'rxjs';

declare var google: any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    SplashScreenComponent
  ],
  providers:[AuthService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Golf.ai';
  isLoading = true;
  // translations: { [key: string]: string } = {};
  constructor(
    private themeService: ThemeService, 
    private translationService: TranslationService, 
    private _authService: AuthService,
    private _settingService: UserSettingService,
    private _sharedService: SharedService,
    private _chatService: ChatService,
    private _router:Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private _mediaService: MediaService
  ){
    effect(() => {
      this.isLoading = this._sharedService.loading();
    })
    this.translationService.currentLang$.subscribe(() => {
      // this.translations = this.translationService.getTranslations();
    });
  }
  ngOnInit(): void {
    // subscribe router event
    this._router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this._sharedService.setPreviousUrl(events[0].urlAfterRedirects);
    });
    // this.translationService.loadTranslations('en');
    if (isPlatformBrowser(this.platformId)) {
      this.themeService.isDarkTheme.subscribe((isDarkTheme) => {
        if (isDarkTheme) {
          document.body.classList.add('dark-theme');
        } else {
          document.body.classList.remove('dark-theme');
        }
      });
      if(this._authService.isSignedIn()){
        this._chatService.getGps();
        this.getUserSetting();
      }
    }
  }
  getUserSetting() {
    this._settingService.getSetting()
      .pipe(
        switchMap((res) => {
          if (res.caddy && res.caddy.image) {
            return this._mediaService.getMedia([res.caddy.image]).pipe(
              map((mediaRes) => {
                res.caddy!.imageUrl = mediaRes[0].url;
                return res;
              })
            );
          }
          return of(res);
        })
      )
      .subscribe({
        next: (res) => {
          this._sharedService.setUser(res);
          this._sharedService.setLocalStorage('email', res.email);
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
        },
      });
  }
  
  setLanguage(lang: string): void {
    this.translationService.setLanguage(lang);
  }

  getReverseGeocodingData(lat: any, lng: any): void {
    if (google){
      const latLong = new google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      const geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({ latLng: latLong },  (results: any, status: any) => {
          if (status !== google.maps.GeocoderStatus.OK) {
              alert(status);
          }
          // This is checking to see if the Geoeode Status is OK before proceeding
          // if (status === google.maps.GeocoderStatus.OK) {
          //   if (results.length > 3){
          //     this.sharedService.location = results[3].formatted_address;
          //   } else if (results.length > 2){
          //     this.sharedService.location = results[2].formatted_address;
          //   } else {
          //     this.sharedService.location = results[1].formatted_address;
          //   }
          // }
      });
    }
  }

}
