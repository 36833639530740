import { Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { noAuthGuard } from './auth/guards/no-auth.guard';
import { authGuard } from './auth/guards/auth.guard';
import { GolfDigestComponent } from './golf-digest/golf-digest.component';
import { GolfDigestLayoutComponent } from './layout/golf-digest-layout/golf-digest-layout.component';
import { GolfDigestLoginLayoutComponent } from './layout/golf-digest-login-layout/golf-digest-login-layout.component';
import { VoiceResponseLayoutComponent } from './layout/voice-response-layout/voice-response-layout.component';
import { VoiceHistoryComponent } from './modules/voice-response/voice-history/voice-history.component';
import { golfDigestAuthGuard } from './auth/guards/golf-digest-auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

export const routes: Routes = [
  // {
  //   path: '',
  //   component: PublicLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: HomeComponent,
  //       canActivate:[noAuthGuard]
  //     },
  //     // {
  //     //   path: 'answer',
  //     //   canActivate:[noAuthGuard],
  //     //   component: PublicShareComponent
  //     // } 
  //   ],
  // },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./modules/auth/auth.route').then((m) => m.AuthRoutes),
  //   canActivate: [noAuthGuard],
  // },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'answer',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/answer/answer.route').then((m) => m.AnswerRoutes),
      },
      {
        path:'home',
        pathMatch: 'full',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/chat/chat.route').then((m) => m.ROUTES)
      },
      {
        path: '',
        pathMatch: 'full',
        canActivate:[noAuthGuard],
        loadChildren: () =>
          import('./modules/home/home.routes').then((m) => m.HomeRoutingModule)
      },
      
      // {
      //   path: 'discover',
      //   loadChildren: () =>
      //     import('./modules/discover/discover.route').then((m) => m.ROUTES),
      //   canActivate: [authGuard],
      // },
      {
        path: 'discover',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/leader-board/leader-board.route').then((m) => m.ROUTES),
          canActivate: [authGuard],
      },
      {
        path: 'settings',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/settings/settings.route').then(
            (m) => m.SettingsRoutes
          ),
        canActivate: [authGuard]
      },
      {
        path: 'voice-response/history',
        pathMatch: 'full',
        canActivate: [authGuard],
        component: VoiceHistoryComponent,
      },
      // {
      //   path: 'map',
      //   loadChildren: () =>
      //     import('./modules/golf-on-course/on-course-map/on-course-map.route').then(
      //       (m) => m.OnCourseMapRoutes
      //     ),
      //   canActivate: [authGuard]
      // },
      {
        path:'on-course',
        loadChildren: () =>
          import('./modules/golf-on-course/on-course/on-course.route').then((m) => m.OnCourseRoutes),
        canActivate: [authGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/public/pubic.route').then((m) => m.PublicRoutes),
      },
    ],
  },
  {
    path: 'voice-response',
    canActivate: [authGuard],
    pathMatch: 'full',
    component: VoiceResponseLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/voice-response/voice-response.route').then(
            (m) => m.VoiceRoutes
          ),
      },
    ],
  },
  // {
  //   path: 'on-course/:id',
  //   component: VoiceResponseLayoutComponent,
  //   children:[{
  //     path:'',
  //     component: InPlayComponent
  //   }],
  // },
  // golf digest layout
  {
    path: 'golf-digest',
    pathMatch: 'full',
    component: GolfDigestLayoutComponent,
    canActivate: [golfDigestAuthGuard],
    children: [
      {
        path: '',
        component: GolfDigestComponent,
      },
    ],
  },
  // Golf Digest login layout
  {
    path: '',
    component: GolfDigestLoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/auth/auth.route').then((m) => m.AuthRoutes),
        canActivate: [noAuthGuard],
      },
    ],
  },
  {
    path: 'error/404',
    component: PageNotFoundComponent
},
{ path: '**', redirectTo: 'error/404' }
];
