import { Component} from '@angular/core';
import { RouterModule } from '@angular/router';
import { User } from '../../model/user.model';

@Component({
  selector: 'splash-screen',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent {
  isSignedIn = false;
  user: User | null = null;
  constructor(
    // private _router: Router,
    // @Inject(PLATFORM_ID) private platformId: object,
    // private _sharedService: SharedService
    
  ){
    // if (isPlatformBrowser(this.platformId)) {
    //   const email = localStorage.getItem('email');
    //   if (email){
    //     this._sharedService.user$.subscribe(user => {
    //       this.user = user;
    //       if(this.user.email){
    //         this.removeLoader()
    //       }
    //     });
    //   } else {
    //     this._router.events
    //       .pipe(
    //           filter(event => event instanceof NavigationEnd),
    //           take(1)
    //       )
    //       .subscribe(() => {
    //         this.removeLoader()
    //       });
    //   }
    // }
  }

  // removeLoader(){
  //   if (isPlatformBrowser(this.platformId)) {
  //     const loaderElement = document.querySelector('splash-screen');
  //     if (loaderElement) {
  //       loaderElement.remove();
  //     }
  //   }
    
  // }

}
