import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet, RouterModule } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-golf-digest-login-layout',
  standalone: true,
  imports: [RouterOutlet, RouterModule, CommonModule],
  templateUrl: './golf-digest-login-layout.component.html',
  styleUrl: './golf-digest-login-layout.component.scss'
})
export class GolfDigestLoginLayoutComponent {
  constructor(
    private _sharedService: SharedService
  ){
    this._sharedService.loading.set(true);
  }
}
