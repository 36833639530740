import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../shared/services/shared.service';
import { User } from '../model/user.model';
import { TranslatePipe } from '../pipes/translate.pipe';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { AuthService } from '../auth/auth.service';
import { ToasterService } from '../shared/services/toaster.service';
import { TokenService } from '../auth/token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserSettingService } from '../services/user-setting.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterOutlet, 
    RouterModule, 
    CommonModule, 
    TranslatePipe,
    AuthModalComponent
  ],
  providers: [],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  authModalRef: NgbModalRef | undefined;
  authPage = 'login';
  openModal = false;
  thisYear = new Date().getFullYear();
  @ViewChild('authModal') authModal: any;
  expandMenu = true;
  private _subscription!: Subscription;
  user: User | null = null;
  prevUrl: string = '';
  currentUrl: string = '';
  isMobileMenuActive = false;
  isSignedIn = false;
  activeTab = '';
  hideExceptTopMenu = false;
  inEmbeddedBrowser = false;
  loadCaddyData = false;
  constructor(
    private _sharedService: SharedService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private _modalService: NgbModal,
    config: NgbModalConfig,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _toaster: ToasterService,
    private _tokenService: TokenService,
    private _settingService: UserSettingService,
    private _cdr: ChangeDetectorRef
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
    config.centered = true;
    this._sharedService.openLoginModal$.subscribe(modal => {
      if(modal.status && this.authModal){
        this.openAuthModal(this.authModal, modal.window);
      }
    })
    router.events.subscribe((val) => {
      if (isPlatformBrowser(this.platformId) && document) {
        const body = document.getElementsByTagName('body')[0];
        if (val instanceof NavigationEnd) {
          const navigation = this.router.getCurrentNavigation();
          this.hideExceptTopMenu = navigation?.extras.state?.['hideExceptTopMenu'] || false;
          this._cdr.markForCheck();
          this.expandMenu = false;
          this.mobileSlideMenu = false;
          this.isMobileMenuActive = false;
          if(!val.url.includes('/settings')){
            this.activeTab = '';
          } else if (val.url.includes('?tab=MyBag')){
            this.activeTab = 'MyBag';
          } else if (val.url.includes('?tab=MyPartner')){
            this.activeTab = 'MyPartner';
          }
          
        }
      }
    });
  }

  ngOnInit() {
      if(isPlatformBrowser(this.platformId)){
        this._activatedRoute.queryParams.subscribe(params => {
          if (params['error']) {
            this.openModal = true;
            this._toaster.error(params['error'],"Error");
            this.clearQueryParams();
          }
          if (params['page'] === 'register') {
              if(params['error']){
                this.authPage = params['page'];
                this.openModal = true;
              } else if (params['access_token'] && params['id_token'] && params['refresh_token']) {
                this._sharedService.loading.set(true);
                this._tokenService.saveTokens({
                  AccessToken: params['access_token'],
                  IdToken: params['id_token'],
                  RefreshToken: params['refresh_token']
                });
                this.openModal = true;
                this.loadCaddyData = true;
                this.authPage = 'language-caddy';
                this.getUserSetting();
              }
          }
          if (params['type'] === 'register') {
            this.openAuthModal(this.authModal, 'register');
          }
        });
        if (this.isEmbeddedBrowser()) {
          this.inEmbeddedBrowser = true;
        }

      if (this.isEmbeddedBrowser()) {
        this.inEmbeddedBrowser = true;

      }
       this.isSignedIn = this._authService.isSignedIn();
       if (!this.isSignedIn){
        this._sharedService.loading.set(false);
       }
       this._sharedService.user$.subscribe(user => {
        this.user = user;
        if(this.user.email){
          this.isSignedIn = true;
        }
        
      });

      this._sharedService.isMyBagActive$.subscribe(res => {
        if(res){
          this.activeTab = res;
        }
      })
    }

     
      
  }

  getUserSetting() {
    if (isPlatformBrowser(this.platformId)) {
      this._settingService.getSetting().subscribe({
        next: (res) => {
          this._sharedService.setUser(res);
          this._sharedService.setLocalStorage('email', res.email);
          this.clearQueryParams();
          this.router.navigateByUrl('/home');
          let socketEnabled = false;
          if(!socketEnabled){
            // this._chatService.onListenWebsocket();
            socketEnabled = true;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err)
        }
      });
    
    }    
  }

  clearQueryParams() {
    this.router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {},
      queryParamsHandling: ''
    });
  }

  navigateToSettingPage(tabName: string){
    // this._sharedService.setByBagSettingTab(tabName);
    // this.router.navigateByUrl("/settings");
    this.router.navigate(["settings"], {
      queryParams: { tab: tabName }, // Set the new query parameter
      queryParamsHandling: 'merge', // Merge with existing query params
    });
    this.activeTab = tabName;
    this.expandMenu = false;
    this.mobileSlideMenu = false;
    this.isMobileMenuActive = false;
  }

  isEmbeddedBrowser(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Detect LinkedIn, Facebook, Instagram embedded browsers
    return /FBAN|FBAV|Instagram|LinkedIn/i.test(userAgent);
  }

  openAuthModal(content: any, modalPage: string){
    if(isPlatformBrowser(this.platformId)){
      if (this.router.url.includes('answer')){
        localStorage.setItem('redirectUrl', this.router.url);
      }
      this.authPage = modalPage;
      this.authModalRef = this._modalService.open(content, { windowClass: 'auth-modal' });
    }
  }

  closeAuthModal(event: any) {
    this.authModalRef?.dismiss();
  }

  ngAfterViewInit(): void {
    // Wait until the authModal is initialized and then open the modal if 'page' param exists
    if (this.authModal && this.authPage && this.openModal) {
      this.openAuthModal(this.authModal, this.authPage);
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  mobileSlideMenu = false;
}
