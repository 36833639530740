import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs';
import { TokenService } from '../token.service';

export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const tokenService = inject(TokenService);
  const router = inject(Router);

  return tokenService.isSignedIn$.pipe(
    take(1),
    map(isSignedIn => {
      if (!isSignedIn) {
        // const currentUrl = state.url;
        // sharedService.setLocalStorage('redirectUrl', currentUrl);
        router.navigate(['/']);
        return false;
      }
      return true;
    })
  );
};